br {
  margin-bottom:5px;
}

button:focus {outline:0;}

.content, .trans-content {
  white-space: pre-line;
}

.app-title {
  color: #606F7B;
}

a {
  text-decoration: none;
}

a.blue, a.blue:visited, a.blue:active {
  color: black;
  text-decoration: none;
  border-bottom: 2px #2779BD solid;
}

.intro a, .intro a:visited, .intro a:active {
  color: black;
  text-decoration: none;
  border-bottom: 2px #2779BD solid;
}

a.board-link, a.board-link:visited, a.board-link:active {
  color: black;
  text-decoration: none;
  border-bottom: 2px #2779BD solid;
}

ul.boxy{
  list-style-type: square !important;
} 

.card .content {
  min-height: 1rem;
}

.dot {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}

img {
  max-width: 100%;
  height: auto;
}

.loader {
  width: 50px;
  height: auto;
  min-height: 50px;
}
.loading {
  margin-top: 0;
  margin-bottom: 5px;
  font-weight: 600;
  color: #3490dc;
  text-align: center;
  text-transform: uppercase;
  font-size: 9px;
}
.bar {
  height: 25px;
  width: 5px;
  background:#3490dc;
  display: block;
  float: left;
  margin-left: 4px;
  animation: height-change 600ms ease-in-out infinite alternate;
  -webkit-animation: height-change 600ms ease-in-out infinite alternate;
  -moz-animation: height-change 600ms ease-in-out infinite alternate;
}
.bar:first-of-type {
  margin-left: 0;
}
.bar-1 {
  animation-delay: 0;
  -webkit-animation-delay: 0;
  -moza-animation-delay: 0;
}
.bar-2 {
  animation-delay: 75ms;
  -webkit-animation-delay: 75ms;
  -moza-animation-delay: 75ms;
}
.bar-3 {
  animation-delay: 150ms;
  -webkit-animation-delay: 150ms;
  -moza-animation-delay: 150ms;
}
.bar-4 {
  animation-delay: 225ms;
  -webkit-animation-delay: 225ms;
  -moza-animation-delay: 225ms;
}
.bar-5 {
  animation-delay: 300ms;
  -webkit-animation-delay: 300ms;
  -moza-animation-delay: 300ms;
}
.bar-6 {
  animation-delay: 375ms;
  -webkit-animation-delay: 375ms;
  -moza-animation-delay: 375ms;
}

@keyframes height-change {
  0% {
      height: 22px;
      background: rgba(67, 112, 159, 1);
  }
  50% {
      height: 15px;
      background: rgba(83, 133, 184, 1);
  }
  100% {
      height: 15px;
      background: rgba(83, 133, 184, 1);
  }
}
@keyframes height-change-out {
  0% {
      height: 15px;
      background: #43709F;
  }
  100% {
      height: 0;
      background: #8DB8E3;
  }
}

.board-box {
  position: absolute;
  display: block;
  width: 250px;
  right: 1rem;
}
.board-box:after, .board-box:before {
	bottom: 100%;
	right: 10%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.board-box:after {
	border-color: rgba(136, 183, 213, 0);
	border-bottom-color: #DAE1E7;
	border-width: 15px;
	margin-left: -15px;
}
.board-box:before {
	border-color: rgba(194, 225, 245, 0);
	border-width: 15px;
	margin-left: -15px;
}

.boards li {
  border-bottom:1px solid #D9D9D9; 
  padding:1em; 
}

.Modal {
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 600px;
  text-align: center;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  padding: 30px;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */

  .Modal {
    width: 90%;
  }
}

.Modal .name {
  padding-bottom: 15px;
}

.Modal .name-input {
  padding-bottom: 50px;
}

.Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(196, 193, 193, 0.884);
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms ease-in-out;
}

.ReactModal__Overlay--after-open{
  opacity: 1;
}

.ReactModal__Overlay--before-close{
  opacity: 0;
}

.ReactModal__Content {
  padding: 20px;
}

.table-wrapper
{
  overflow-y:auto;
}
